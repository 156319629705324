import React from "react";

import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { Button, Modal } from "react-bootstrap";
import { erpQuery } from "../utils/erpClient";
import { printFile } from "../utils/print";
import { getSetting } from "../utils/settings";
import saveAs from "file-saver";
import { AuthContext } from "../utils/AuthProvider";

interface ProductPackagingSlipModalProps {
  close: () => void;
}

const ProductPackagingSlipModal = (props: ProductPackagingSlipModalProps) => {
  type SearchResult = { description: string; value: string };
  const [isLoading, setIsLoading] = React.useState(false);
  const [options, setOptions] = React.useState<SearchResult[]>([]);
  const [selected, setSelected] = React.useState<SearchResult[]>([]);

  const authContext = React.useContext(AuthContext);

  const printProductPackagingSlip = () => {
    printFile({
      uri: `/api/combinedPackagingSlip`,
      printer: getSetting("printerInvoices"),
      preset: "A4",
      title: "Produktpackliste",
      headers: {
        "Content-Type": "application/json",
        Authorization: Object.values(authContext.getAuth()).find(
          (user) => user.active
        )?.token,
      },
      method: "post",
      body: JSON.stringify({ items: selected.map((item) => item.value) }),
    });
    props.close();
  };

  return (
    <Modal show={true} onHide={props.close}>
      <Modal.Header>Produktpackzettel drucken</Modal.Header>
      <Modal.Body>
        <p>Artikel auswählen:</p>
        <AsyncTypeahead
          id="product-selection"
          isLoading={isLoading}
          multiple
          // @ts-ignore
          labelKey={(option: SearchResult) => `${option.value}`}
          // @ts-ignore
          onChange={(selected: SearchResult[]) => setSelected(selected)}
          onSearch={(query) => {
            setIsLoading(true);
            const params = new URLSearchParams({
              txt: query,
              doctype: "Item",
              ignore_user_permissions: "0",
              query: "erpnext.controllers.queries.item_query",
              filters: '{"is_sales_item":1}',
            });

            erpQuery({
              uri: "method/frappe.desk.search.search_link",
              authContext,
              method: "POST",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
              body: params.toString(),
            }).then(
              (query: {
                results: { description: string; value: string }[];
              }) => {
                setOptions(query.results);
                setIsLoading(false);
              }
            );
          }}
          options={options}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.close}>Abbrechen</Button>
        <Button
          onClick={async () => {
            let pdf = await fetch(`/api/combinedPackagingSlip`, {
              method: "post",
              headers: {
                "Content-Type": "application/json",
                Authorization:
                  Object.values(authContext.getAuth()).find(
                    (user) => user.active
                  )?.token ?? "",
              },
              body: JSON.stringify({
                items: selected.map((item) => item.value),
              }),
            });
            saveAs(await pdf.blob(), `Packzettel.pdf`);
          }}
        >
          Speichern
        </Button>
        <Button onClick={printProductPackagingSlip}>Drucken</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProductPackagingSlipModal;
