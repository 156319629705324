import React from "react";

import { printFile } from "../utils/print";

import { SalesOrderItem } from "./SalesOrderItem";
import { useQuery } from "react-query";
import { customerLoader, itemDetail } from "../utils/erpQueries";
import { getSetting } from "../utils/settings";
import { useRouter } from "next/router";
import {
  Badge,
  Button,
  Card,
  Form,
  Modal,
  OverlayTrigger,
  Table,
  Tooltip,
} from "react-bootstrap";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AuthContext } from "../utils/AuthProvider";

interface SalesOrderProps {
  salesOrder: any;
  comments?: [
    {
      content: string;
      creation: string;
    }
  ];
  index: number;
  itemDetails: itemDetail[];
  selected: boolean;
  showOrderDetails: boolean;
  toggleSelected: () => void;
}

const SalesOrder = (props: SalesOrderProps) => {
  const { data: customerDetails } = useQuery(
    ["customer", props.salesOrder.customer],
    () => customerLoader.load(props.salesOrder.customer),
    { refetchOnWindowFocus: false }
  );

  const [documentPrintDialogOpen, setDocumentPrintDialogOpen] =
    React.useState(false);
  const [documentToPrint, setDocumentToPrint] = React.useState<{
    uri: string;
    title: string;
  }>({ uri: "", title: "" });

  const authContext = React.useContext(AuthContext);

  const router = useRouter();

  const printDocument = () => {
    printFile({
      uri: documentToPrint.uri,
      printer: getSetting("printerInvoices"),
      preset: "A4",
      title: documentToPrint.title,
      headers: {
        Authorization: `Bearer ${
          Object.values(authContext.getAuth()).find((user) => user.active)
            ?.token
        }`,
      },
    });
    setDocumentPrintDialogOpen(false);
  };

  const openSalesOrderDetails = () => {
    router.push({
      pathname: "/ERPSalesOrder",
      query: { salesOrder: props.salesOrder.name },
    });
  };

  const deliveryTypeChipColor = (deliverytype: string) => {
    switch (deliverytype) {
      case "Paketversand":
        return "primary";
      default:
        return "secondary";
    }
  };

  return (
    <>
      <Modal
        show={documentPrintDialogOpen}
        onHide={() => setDocumentPrintDialogOpen(false)}
      >
        <Modal.Header>Dokument drucken</Modal.Header>
        <Modal.Body>
          Möchtest Du das Dokument {documentToPrint.title} direkt drucken? (A4
          Papier eingelegt?)
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={printDocument} variant="primary" autoFocus>
            Drucken
          </Button>
          <Button
            href={documentToPrint.uri}
            onClick={() => setDocumentPrintDialogOpen(false)}
            variant="primary"
          >
            PDF anzeigen
          </Button>
        </Modal.Footer>
      </Modal>

      <tr className={props.index % 2 ? "table-light" : ""}>
        <td>
          <Form.Check
            checked={props.selected}
            onChange={() => props.toggleSelected()}
          />
        </td>
        <td onClick={openSalesOrderDetails}>
          {new Intl.DateTimeFormat("de-DE", {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
          }).format(new Date(props.salesOrder.delivery_date))}
          {props.salesOrder.in_process_by && (
            <Badge bg="warning">
              In Bearbeitung durch
              <br />
              {props.salesOrder.in_process_by}
            </Badge>
          )}
        </td>
        <td
          className={
            props.salesOrder.customer_group === "Einzelperson"
              ? "consumerCustomer"
              : ""
          }
          onClick={openSalesOrderDetails}
        >
          {" "}
          {/* TODO: make configurable */}
          {props.salesOrder.shipping_address_name != ""
            ? props.salesOrder.shipping_address_name
            : props.salesOrder.customer}
        </td>
        <td onClick={openSalesOrderDetails}>
          <Badge bg={deliveryTypeChipColor(props.salesOrder.deliverytype)}>
            {props.salesOrder.deliverytype}
          </Badge>
        </td>
        <td align="right">
          {props.salesOrder.invoices?.map((invoice) => (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>{invoice}</Tooltip>}
              key={invoice}
            >
              <Button
                className="rounded-circle"
                size="sm"
                onClick={(e) => {
                  setDocumentToPrint({
                    uri: `${process.env.NEXT_PUBLIC_ERPNEXT_URL}/api/method/frappe.utils.print_format.download_pdf?doctype=Sales%20Invoice&name=${invoice}&_lang=de`,
                    title: invoice,
                  });
                  setDocumentPrintDialogOpen(true);
                }}
              >
                <FontAwesomeIcon icon={faFile} />
              </Button>
            </OverlayTrigger>
          ))}
        </td>
      </tr>

      {props.showOrderDetails && (
        <tr
          key={`{row.name}-content`}
          className={props.index % 2 ? "table-light" : ""}
        >
          <td colSpan={5} className="ps-4">
            <Table
              className="table-sm border border-2"
              style={{ fontSize: ".8rem" }}
            >
              <tbody>
                {props.salesOrder.items?.map((shipmentItem, index) => (
                  <SalesOrderItem
                    key={index}
                    item={shipmentItem}
                    itemDetail={props.itemDetails.find(
                      (item) => item.name === shipmentItem.item_code
                    )}
                  />
                ))}
              </tbody>
            </Table>

            {(props.comments?.length > 0 ||
              customerDetails?.customer_details) && (
              <Card>
                <Card.Header>Kommentare</Card.Header>
                <Table size="small" style={{ paddingLeft: 30 }}>
                  <tbody>
                    {props.comments?.map((comment, index) => (
                      <tr key={index}>
                        <td
                          dangerouslySetInnerHTML={{ __html: comment.content }}
                        />
                      </tr>
                    ))}
                    {customerDetails?.customer_details && (
                      <tr>
                        <td
                          dangerouslySetInnerHTML={{
                            __html: customerDetails?.customer_details,
                          }}
                        />
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card>
            )}
          </td>
        </tr>
      )}
    </>
  );
};

export default SalesOrder;
