import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import saveAs from "file-saver";
import type { NextPage } from "next";
import React from "react";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Pagination,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { useQueries, useQuery, useQueryClient } from "react-query";
import ProductPackagingSlipModal from "../components/ProductPackagingSlipModal";
import SalesOrder from "../components/SalesOrder";
import { AuthContext } from "../utils/AuthProvider";
import {
  customerLoader,
  fetchComments,
  fetchDoc,
  fetchOrderCount,
  fetchOrders,
  itemDetail,
} from "../utils/erpQueries";
import { printFile } from "../utils/print";
import { getAllItemDetails } from "../utils/serverErpQueries";
import { getSetting } from "../utils/settings";

export async function getStaticProps(): Promise<{
  props: { erpItemData: itemDetail[] };
  revalidate: number;
}> {
  let erpItemData = await getAllItemDetails();
  return {
    props: {
      erpItemData,
    },
    revalidate: 60 * 60 * 2,
  };
}

interface TablePaginationProps {
  page: number;
  setPage: (page: number) => void;
  rowsPerPage: number;
  totalCount: number;
}
const TablePagination = (props: TablePaginationProps) => {
  return (
    <div>
      <Pagination className="mb-0">
        <Pagination.First
          onClick={() => props.setPage(0)}
          disabled={props.page === 0}
        />
        <Pagination.Prev
          onClick={() => props.setPage(props.page - 1)}
          disabled={props.page === 0}
        />

        {props.page > 2 && <Pagination.Ellipsis disabled />}
        {props.page > 0 && (
          <Pagination.Item onClick={() => props.setPage(props.page - 1)}>
            {props.page}
          </Pagination.Item>
        )}
        <Pagination.Item active>{props.page + 1}</Pagination.Item>
        {props.page < props.totalCount / props.rowsPerPage - 1 && (
          <Pagination.Item onClick={() => props.setPage(props.page + 1)}>
            {props.page + 2}
          </Pagination.Item>
        )}

        {props.page < props.totalCount / props.rowsPerPage - 2 && (
          <Pagination.Ellipsis disabled />
        )}

        <Pagination.Next
          onClick={() => props.setPage(props.page + 1)}
          disabled={props.page > props.totalCount / props.rowsPerPage - 1}
        />
        <Pagination.Last
          onClick={() =>
            props.setPage(Math.floor(props.totalCount / props.rowsPerPage))
          }
          disabled={props.page > props.totalCount / props.rowsPerPage - 1}
        />
      </Pagination>
      <span>{props.totalCount} Bestellungen</span>
    </div>
  );
};

const Home: NextPage<{ erpItemData: [itemDetail] }> = ({
  erpItemData,
}: {
  erpItemData: [itemDetail];
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [showOnlyOpenOrders, setShowOnlyOpenOrders] = React.useState(true);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [showOrderDetails, setShowOrderDetails] = React.useState(true);
  const [showPrintPackagingSlips, setShowPrintPackagingSlips] =
    React.useState(false);
  const [showPrintCombinedPackagingSlip, setShowPrintCombinedPackagingSlip] =
    React.useState(false);
  const [showPrintProductPackagingSlip, setShowPrintProductPackagingSlip] =
    React.useState(false);

  const selectedOrdersReducer = (
    state: string[],
    action: { type: "toggle"; order: string } | { type: "clear" }
  ): string[] => {
    switch (action.type) {
      case "toggle":
        let newState = [...state];
        if (state.includes(action.order)) {
          newState.splice(state.indexOf(action.order), 1);
        } else {
          newState.push(action.order);
        }
        return newState;
      case "clear":
        return [];
      default:
        return state;
    }
  };
  const [selectedOrders, selectedOrdersDispatch] = React.useReducer(
    selectedOrdersReducer,
    []
  );

  const authContext = React.useContext(AuthContext);
  const queryClient = useQueryClient();

  const printPackagingSlips = () => {
    for (let order of selectedOrders) {
      printFile({
        uri:
          `${process.env.NEXT_PUBLIC_ERPNEXT_URL}/api/method/` +
          `frappe.utils.print_format.download_pdf?doctype=Sales%20Order&` +
          `name=${order}&format=Packzettel&no_letterhead=0&` +
          `letterhead=BFD&settings=%7B%7D&_lang=de`,
        printer: getSetting("printerInvoices"),
        preset: "A4",
        title: order,
        headers: {
          Authorization: `Bearer ${
            Object.values(authContext.getAuth()).find((user) => user.active)
              ?.token
          }`,
        },
      });
    }
    setShowPrintPackagingSlips(false);
    selectedOrdersDispatch({ type: "clear" });
  };

  const printCombinedPackagingSlip = () => {
    for (let order of selectedOrders) {
      printFile({
        uri: `/api/combinedPackagingSlip`,
        printer: getSetting("printerInvoices"),
        preset: "A4",
        title: order,
        headers: {
          "Content-Type": "application/json",
          Authorization: Object.values(authContext.getAuth()).find(
            (user) => user.active
          )?.token,
        },
        method: "post",
        body: JSON.stringify({ orders: selectedOrders }),
      });
    }
    setShowPrintCombinedPackagingSlip(false);
    selectedOrdersDispatch({ type: "clear" });
  };

  const { data: orders, isLoading: ordersLoading } = useQuery(
    ["orders", { showOnlyOpenOrders, page, rowsPerPage }, searchQuery],
    () =>
      fetchOrders({
        showOnlyOpenOrders,
        page,
        rowsPerPage,
        searchQuery,
        authContext,
      }),
    {
      onSuccess: (data) =>
        data &&
        data.forEach((order) =>
          queryClient.prefetchQuery(["SalesOrder", order.name], () =>
            fetchDoc(order.name, "Sales Order", authContext)
          )
        ),
      enabled: Object.keys(authContext.getAuth()).length > 0,
    }
  );

  const { data: comments } = useQuery<any>(
    ["comments", ...(orders?.map((order) => order.name) ?? ["empty"])],
    () => fetchComments(orders?.map((order) => order.name) ?? [], authContext),
    { enabled: !!orders }
  );
  const { data: totalCount } = useQuery<number>(
    ["totalOrders", showOnlyOpenOrders, searchQuery],
    () => fetchOrderCount(showOnlyOpenOrders, searchQuery, authContext),
    { enabled: Object.keys(authContext.getAuth()).length > 0 }
  );

  // prefetch customer details
  useQueries(
    [...new Set<any>(orders?.map((order) => order.customer))].map(
      (customer) => ({
        queryKey: ["customer", customer],
        queryFn: () => customerLoader.load(customer),
        enabled: !!orders && Object.keys(authContext.getAuth()).length > 0,
        refetchOnWindowFocus: false,
      })
    )
  );

  return (
    <>
      <Modal show={showPrintPackagingSlips}>
        <Modal.Header>Packzettel drucken</Modal.Header>
        <Modal.Body>
          Packzettel zu folgenden Aufträgen drucken?
          <p>{selectedOrders.join(", ")}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowPrintPackagingSlips(false)}>
            Abbrechen
          </Button>
          <Button onClick={printPackagingSlips}>Drucken</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showPrintCombinedPackagingSlip}>
        <Modal.Header>Sammelpackzettel drucken</Modal.Header>
        <Modal.Body>
          Packzettel zu folgenden Aufträgen drucken?
          <p>{selectedOrders.join(", ")}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowPrintCombinedPackagingSlip(false)}>
            Abbrechen
          </Button>
          <Button
            onClick={async () => {
              let pdf = await fetch(`/api/combinedPackagingSlip`, {
                method: "post",
                headers: {
                  "Content-Type": "application/json",
                  Authorization:
                    Object.values(authContext.getAuth()).find(
                      (user) => user.active
                    )?.token ?? "",
                },
                body: JSON.stringify({ orders: selectedOrders }),
              });
              saveAs(await pdf.blob(), `Packzettel.pdf`);
            }}
          >
            Speichern
          </Button>
          <Button onClick={printCombinedPackagingSlip}>Drucken</Button>
        </Modal.Footer>
      </Modal>

      {showPrintProductPackagingSlip && (
        <ProductPackagingSlipModal
          close={() => setShowPrintProductPackagingSlip(false)}
        />
      )}

      <h3>Bestellungen</h3>

      <Container className="mt-3">
        <Row className="g-2">
          <Col xs={12} md={6} lg={4}>
            <TablePagination
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              totalCount={totalCount ?? 0}
            />
          </Col>
          <Col xs={4} md={2}>
            <Form.Select
              style={{ width: "5em" }}
              value={rowsPerPage}
              onChange={(e) => setRowsPerPage(parseInt(e.target.value))}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
              <option value={40}>40</option>
              <option value={50}>50</option>
            </Form.Select>
          </Col>
          <Col xs={8} md={3}>
            <InputGroup className="mb-2 shadow-sm">
              <InputGroup.Text
                style={{ background: "none", paddingLeft: ".5em" }}
              >
                <FontAwesomeIcon icon={faSearch} className="text-muted" />
              </InputGroup.Text>
              <Form.Control
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                type="text"
                placeholder="Suche..."
                style={{ borderLeft: "none", borderRight: "none" }}
              />
              {searchQuery != "" && (
                <InputGroup.Text
                  style={{
                    cursor: "pointer",
                    background: "none",
                    paddingLeft: ".5em",
                  }}
                  onClick={() => setSearchQuery("")}
                >
                  <FontAwesomeIcon icon={faTimes} className="text-muted" />
                </InputGroup.Text>
              )}
            </InputGroup>
          </Col>
          <Col xs={12} md={2}>
            <Form.Switch
              label="Nur offene Bestellungen anzeigen"
              checked={showOnlyOpenOrders}
              onChange={() => setShowOnlyOpenOrders(!showOnlyOpenOrders)}
            />
          </Col>
        </Row>
      </Container>

      <Container className="d-flex flex-row gap-3">
        <Form.Switch
          label="Artikel anzeigen"
          checked={showOrderDetails}
          className="mt-2"
          onChange={() => setShowOrderDetails(!showOrderDetails)}
        />
        <Button
          size="sm"
          onClick={() => setShowPrintProductPackagingSlip(true)}
        >
          Produktpackzettel drucken
        </Button>
        {selectedOrders.length > 0 && (
          <Button size="sm" onClick={() => setShowPrintPackagingSlips(true)}>
            Packzettel drucken
          </Button>
        )}
        {selectedOrders.length > 0 && (
          <Button
            size="sm"
            onClick={() => setShowPrintCombinedPackagingSlip(true)}
          >
            Sammelpackzettel drucken
          </Button>
        )}
      </Container>

      <Table className="table-borderless">
        <thead>
          <tr>
            <th></th>
            <th>Lieferdatum</th>
            <th>Empfänger</th>
            <th align="left">Versand</th>
            <th align="right">Dokumente</th>
          </tr>
        </thead>
        <tbody>
          {ordersLoading && (
            <tr>
              <td colSpan={5} align="center">
                <Spinner animation="border" />
              </td>
            </tr>
          )}
          {!ordersLoading &&
            orders?.map((row, index) => (
              <React.Fragment key={index}>
                <SalesOrder
                  itemDetails={erpItemData}
                  salesOrder={row}
                  comments={comments ? comments[row.name] ?? [] : []}
                  index={index}
                  key={index}
                  showOrderDetails={showOrderDetails}
                  selected={selectedOrders.includes(row.name)}
                  toggleSelected={() =>
                    selectedOrdersDispatch({ type: "toggle", order: row.name })
                  }
                />
              </React.Fragment>
            ))}
        </tbody>
      </Table>
      <TablePagination
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        totalCount={totalCount ?? 0}
      />
    </>
  );
};

export default Home;
