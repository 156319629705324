export const printBlob = async ({
  blob,
  printer,
  title,
  preset,
  copies = 1,
  raw = false,
}: {
  blob: Blob | string;
  printer: string;
  title: string;
  preset: string;
  copies?: number;
  raw?: boolean;
}) => {
  if (typeof blob === "object") {
    const filereader = new FileReader();
    let resultPromise = new Promise<string>((resolve) => {
      filereader.onloadend = () => {
        // @ts-ignore
        resolve(filereader.result);
      };
    });
    filereader.readAsDataURL(blob);
    var b64data: string = await resultPromise;
  } else {
    var b64data: string = `base64,${Buffer.from(blob, "binary").toString(
      "base64"
    )}`;
  }

  await fetch(`http://${process.env.NEXT_PUBLIC_PRINT_PROXY}/print-pdf`, {
    method: "POST",
    body: JSON.stringify({
      preset,
      printer,
      title,
      file: b64data,
      copies,
      raw,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const printZPL = async ({
  zpl,
  printer,
}: {
  zpl: string;
  printer: string;
}) => {
  await fetch(`http://${process.env.NEXT_PUBLIC_PRINT_PROXY}/print-zpl`, {
    method: "POST",
    body: JSON.stringify({
      printer: printer,
      data: zpl,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const printFile = async ({
  uri,
  printer,
  title,
  preset,
  headers,
  method,
  body,
}: {
  uri: string;
  printer: string;
  title: string;
  preset: string;
  headers?: {};
  method?: "get" | "post";
  body?: string;
}) => {
  const result = await fetch(uri, {
    method: method ?? "get",
    headers,
    body,
  });

  const blob = await result.blob();
  printBlob({
    blob,
    printer,
    title,
    preset,
  });
};
